import React, { useEffect, useState } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Input,
  Row,
  Col,
  DatePicker,
  message,
  Modal,
  Divider,
  Alert,
  Tooltip
} from "antd";
import { withRouter } from "react-router-dom";
import { ExpedientTypeSelect } from "../../PrebuiltFormComponents";
import ExpedientService from "../../../services/expedient.service";
import moment from "moment";
import UserSelect from "../../PrebuiltFormComponents/UserSelect";
import { explodeTags } from "../../PrebuiltFormComponents/TagsInput";
import JurisdictionSelect from "../../PrebuiltFormComponents/JurisdictionSelect";
import { FueroSelect } from "../../PrebuiltFormComponents/FueroSelect";
import { CourtSelect } from "../../PrebuiltFormComponents/CourtSelect";
import { SecretarySelect } from "../../PrebuiltFormComponents/SecretarySelect";
import ClientSelect from "../../PrebuiltFormComponents/ClientSelect";
import TagAutocomplete from "../../PrebuiltFormComponents/TagAutocomplete";
import CheckedExpedientsTable from "../../CheckedExpedientsTable";
import { useMediaQuery } from "react-responsive";
import { isTutorialCompleted } from "../../../containers/Frames/OverlayTutorial";
import Antd4ProvinceSelect from "../../PrebuiltFormComponents/Antd4ProvinceSelect";
import { ExpiresAtInput } from "./ExpiresAtInput";
import StudioService from "../../../services/studio.service";
import useTutorialStateV2 from "../../../unstatedStores/TutorialStateV2";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { ReactComponent as CalendarIcon } from "../../../assets/assets2_0/calendar-2.svg";
import CustomSwtichComponent from "../../PrebuiltFormComponents/CustomSwitchComponent";
import { connect } from "react-redux";
import { history } from "../../../appRedux/store";
import CollapsablePanel from "../../../util/CollapsablePanel";
import { MevCausaInput } from "./MevCausaInput";

const CreateExpedient = props => {
  const {
    form,
    expedient,
    onCancel,
    withTutorial,
    authUser,
    currentLang
  } = props;
  const { getFieldDecorator, getFieldError, isFieldTouched } = form;

  const [loading, setLoading] = useState(false);

  const editing = Boolean(expedient);

  //Auto increment folder
  const [nextFolderNumber, setNextFolderNumber] = useState(undefined);
  const [usePrefixes, setUsePrefixes] = useState(false);
  const [autoFolder, setAutoFolder] = useState(undefined);
  const [isPublic, setIsPublic] = useState(
    editing ? expedient.is_public : true
  );

  const linkingClient = history.location.state && history.location.state.client;
  const clientToLink = linkingClient ? history.location.state.client : null;

  const [expiresInDisabled, setExpiresInDisabled] = useState(editing && expedient.expires_in < 0);

  const { confirm } = Modal;
  const user = JSON.parse(localStorage.getItem("user"));
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const [selectedResponsibles, setSelectedResponsibles] = useState(editing
    ? expedient.expedient_responsibles.flatMap(r => r.id)
    : [user.id]);
  const canBeSetAsPrivate = selectedResponsibles && selectedResponsibles.length > 0;

  const [jurisdiction, setJurisdiction] = useState(editing ? expedient.jurisdiction_id : null);
  const [fuero, setFuero] = useState(editing ? expedient.fuero : null);
  const [court, setCourt] = useState(editing ? expedient.court : null);
  const [secretary, setSecretary] = useState(editing ? expedient.secretary : null);

  const [tutoStep, setTutoStep] = useState(1);

  const [checkExpedient, setCheckExpedient] = useState();

  const [showAdvancedFields, setShowAdvancedFields] = useState(false);
  const [newClient, setNewClient] = useState(null);
  // const tutorialState = TutorialStateContainer.useContainer();
  const tutorialState = useTutorialStateV2();
  const showTutorial =
    tutorialState.showTuto &&
    !isTutorialCompleted("CREATE_EXPEDIENT") &&
    process.env.REACT_APP_SHOW_TUTORIAL === "1";
  const [province, setProvince] = useState(
    expedient
      ? expedient.expedient_province || authUser.province_id
      : authUser.studio.province_id
  );
  const { mev_scraping: mevScrapingEnabled } = authUser.studio || { mev_scraping: true };

  useEffect(() => {
    if (user.studio && !expedient) {
      StudioService.getNextFolder(user.studio.id).then(res => {
        if (res.status === 200) {
          setNextFolderNumber(res.data.next_folder);
          setUsePrefixes(res.data.use_prefixes);
        }
      });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        //Si se modifica el numero de carpeta auto asignado, el expediente deja de contar como autoincremetado
        if (nextFolderNumber) {
          if (autoFolder === values.folder) {
            values = {
              ...values,
              auto_incremented_folder: true
            };
          }
        }
        if (
          expedient &&
          expedient.auto_incremented_folder &&
          values.folder !== expedient.folder
        ) {
          values = {
            ...values,
            auto_incremented_folder: false
          };
        }
        setLoading(true);
        expedient
          ? updateExpedient(
            {
              ...values,
              jurisdiction_id: jurisdiction,
              fuero_id: fuero,
              court_id: court,
              secretary_id: secretary,
              expires_in: expiresInDisabled ? -1 : values.expires_in
            },
            props,
            () => {
              setLoading(false);
            }
          )
          : storeExpedient(
            {
              ...values,
              expires_in: expiresInDisabled ? -1 : values.expires_in
            },
            props,
            () => {
              setLoading(false);
            },
            withTutorial
          );
      }
    });
  };

  const debouncedCheck = async (fieldKey, fieldLabel, fieldData) => {
    if (fieldData !== "" && fieldData !== null && fieldData !== undefined) {
      clearTimeout(checkExpedient);
      setCheckExpedient(
        setTimeout(() => {
          setLoading(true);
          ExpedientService.expedientExists({
            [[fieldKey]]: fieldData
          })
            .then(resp => {
              if (resp && resp.data.length > 0) {
                showConfirm(fieldKey, fieldLabel, resp.data, currentLang);
              }
            })
            .catch(e => {
              message.error(`No se pudo comprobar el numero de expediente`);
              console.log(e);
            })
            .finally(() => setLoading(false));
        }, 1200)
      );
    }
  };

  const showConfirm = (fieldKey, fieldLabel, expedients, currentLang) => {
    confirm({
      width: isTabletOrMobile ? "100vw" : "50vw",
      zIndex: 1050,
      icon: null,
      okText: `Aceptar`,
      title: `El campo ${fieldLabel} ya se encuentra ${expedients.length > 1
        ? `asignado a los siguientes expedientes`
        : `asignado al siguiente expediente`
        } `,
      cancelButtonProps: { style: { display: "none" } },
      content: (
        <div>
          <CheckedExpedientsTable
            currentLang={currentLang}
            fieldKey={fieldKey}
            fieldLabel={fieldLabel}
            onUpdate={expedient => {
              props.history.push(`/expedient/details#${expedient.uuid}`, {
                editing: true
              });
              Modal.destroyAll();
            }}
            expedients={expedients}
          />
        </div>
      ),
      onOk: () => {
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      }
    });
  };

  const fields = [
    {
      key: "client",
      lg: 8,
      md: 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 2,
      item: (
        <ClientSelect
          showLinkContactButton={false}
          form={form}
          autoFocus={true}
          client={
            expedient ? expedient.client : linkingClient ? clientToLink : null
          }
          getFieldDecorator={getFieldDecorator}
          required={false}
        />
      )
    },
    {
      key: "expedient_type",
      lg: 8,
      md: 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 3,
      item: (
        <ExpedientTypeSelect
          onChange={exp_type => {
            props.form.setFieldsValue(
              exp_type
                ? { expires_in: exp_type.expires_in }
                : { expires_in: undefined }
            );
            setExpiresInDisabled(exp_type ? exp_type.expires_in <= 0 : false);


            if (nextFolderNumber) {
              if (
                usePrefixes &&
                exp_type.increment_folder_settings &&
                exp_type.increment_folder_settings.length > 0 &&
                exp_type.increment_folder_settings[0].enabled
              ) {
                let new_folder = `${exp_type.increment_folder_settings[0].char
                  }${nextFolderNumber[exp_type.id]}`;
                setAutoFolder(new_folder);
                props.form.setFieldsValue({ folder: new_folder });
                debouncedCheck("folder", "Nro carpeta", new_folder);
              } else {
                let new_folder = nextFolderNumber.no_letter;
                setAutoFolder(new_folder);
                props.form.setFieldsValue({ folder: new_folder });
                debouncedCheck("folder", "Nro carpeta", new_folder);
              }
            }
          }}
          expedientType={expedient ? expedient.expedient_type.id : Number.parseInt(process.env.REACT_APP_UNASSIGNED_EXPEDIENT_TYPE_ID)}
          getFieldDecorator={getFieldDecorator}
        />
      )
    },
    {
      key: "expedient_number",
      lg: 8,
      md: 8,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 7,
      item: (
        <Form.Item key="expedient_number" label="Numero de expediente">
          {getFieldDecorator("expedient_number", {
            initialValue: expedient ? expedient.expedient_number : null
          })(
            <Input
              className="responsive-input"
              autoComplete="cc-csc"
              onChange={e =>
                debouncedCheck(
                  "expedient_number",
                  "N° expediente",
                  e.currentTarget.value
                )
              }
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "front",
      lg: 24,
      md: 24,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 5,
      item: (
        <Form.Item key="front" label="Caratula">
          {getFieldDecorator("front", {
            rules: [
              {
                required: true,
                message: "Debe indicar la caratula de expediente."
              }
            ],
            initialValue: editing ? expedient.front : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      key: "folder",
      lg: 6,
      md: 6,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 8,
      item: (
        <Form.Item key="folder" label="Nro de carpeta">
          {getFieldDecorator("folder", {
            initialValue: editing ? expedient.folder : null
          })(
            <Input
              className="responsive-input"
              autoComplete="cc-csc"
              onChange={e =>
                debouncedCheck("folder", "Nro carpeta", e.currentTarget.value)
              }
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "tags",
      lg: 18,
      md: 18,
      xs: 18,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 11,
      item: (
        <TagAutocomplete
          initialValue={editing ? expedient.tags.flatMap(t => t.name) : []}
          label="Tags"
          name="tags"
          filter="expedients"
          getFieldDecorator={getFieldDecorator}
        />
      )
    },
    {
      key: "observation",
      lg: 24,
      md: 24,
      xs: 24,
      _tutoStep: 15,
      cond: true,
      roles: [],
      showOnEdit: true,
      item: (
        <Form.Item key="observation" label="Observaciones">
          {getFieldDecorator("observation", {
            initialValue: editing ? expedient.observation : null
          })(
            <Input.TextArea
              className="responsive-input"
              placeholder="Observaciones"
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "mev_causa",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: mevScrapingEnabled,
      roles: [],
      showOnEdit: true,
      _tutoStep: 12,
      item: <MevCausaInput
        editing={editing}
        expedient={expedient}
        getFieldDecorator={getFieldDecorator}
        getFieldError={getFieldError}
        isFieldTouched={isFieldTouched}
        key={"mev_causa"}
      />
    }
  ];

  const advanced_fields = [
    {
      key: "responsibles",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 9,
      item: (
        <UserSelect
          onUserSelect={(user_ids) => {
            setSelectedResponsibles(user_ids);
          }}
          label="Responsable(s)"
          formKey="responsibles"
          mode="multiple"
          hideOnSelect={false}
          users={selectedResponsibles}
          getFieldDecorator={getFieldDecorator}
          required={!isPublic}
        />
      )
    },
    {
      key: "is_public",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 6,
      item: (
        <Form.Item key="is_public" label="Es público">
          {getFieldDecorator("is_public", {
            rules: [
              {
                required: true,
                message: "Debe indicar si es publico o no."
              }
            ],
            valuePropName: "checked",
            initialValue: editing ? expedient.is_public : true
          })(
            <CustomSwtichComponent
              disabledNo={!canBeSetAsPrivate}
              fieldName={"is_public"}
              value={isPublic}
              onSelect={val => setIsPublic(val)}
              form={form}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "init_date",
      lg: editing ? 6 : 8,
      md: editing ? 6 : 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 4,
      item: (
        <Form.Item key="init_date" label="Fecha de inicio">
          {getFieldDecorator("init_date", {
            rules: [
              {
                required: true,
                message: "Este campo es requerido."
              }
            ],
            initialValue: editing ? moment(expedient.init_date) : moment()
          })(
            <DatePicker
              className="responsive-input"
              format="DD/MM/YYYY"
              suffixIcon={<CalendarIcon />}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "demand_begin_date",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 13,
      item: (
        <Form.Item
          key="demand_begin_date"
          label={currentLang.text.fecha_inicio}
        >
          {getFieldDecorator("demand_begin_date", {
            initialValue: editing
              ? expedient.demand_begin_date
                ? moment(expedient.demand_begin_date)
                : null
              : null
          })(
            <DatePicker
              className="responsive-input"
              format="DD/MM/YYYY"
              suffixIcon={<CalendarIcon />}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "prescription_date",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 14,
      item: (
        <Form.Item key="prescription_date" label="Fecha de prescripción">
          {getFieldDecorator("prescription_date", {
            initialValue: editing
              ? expedient.prescription_date
                ? moment(expedient.prescription_date)
                : null
              : null
          })(
            <DatePicker
              className="responsive-input"
              format="DD/MM/YYYY"
              suffixIcon={<CalendarIcon />}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "expires_in",
      lg: editing ? 6 : 8,
      md: editing ? 6 : 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 14,
      item: (
        <Form.Item
          key="expires_in" label="Caducar en (dias)">
          {getFieldDecorator("expires_in", {
            initialValue: editing ? expedient.expires_in : ""
          })(<ExpiresAtInput onDisable={setExpiresInDisabled} />)}
        </Form.Item>
      )
    },
    {
      key: "claimed_amount",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 10,
      item: (
        <Form.Item key="claimed_amount" label="Monto Reclamado">
          {getFieldDecorator("claimed_amount", {
            initialValue: editing ? expedient.claimed_amount : 0
          })(
            <Input
              className="responsive-input"
              autoComplete="cc-csc"
              suffix={
                <DollarOutlined
                  style={{ color: "#0042c5", fontSize: "1.5em" }}
                />
              }
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "claim_date",
      lg: editing ? 9 : 8,
      md: editing ? 9 : 8,
      xs: 24,
      cond: tutoStep !== 15,
      roles: [],
      showOnEdit: true,
      _tutoStep: 12,
      item: (
        <Form.Item key="claim_date" label="Fecha de reclamo">
          {getFieldDecorator("claim_date", {
            initialValue: editing
              ? expedient.claim_date
                ? moment(expedient.claim_date)
                : null
              : null
          })(
            <DatePicker
              className="responsive-input"
              format="DD/MM/YYYY"
              suffixIcon={<CalendarIcon />}
            />
          )}
        </Form.Item>
      )
    }
  ];

  const judicial_fields = [
    {
      key: "province",
      lg: 24,
      md: 24,
      xs: 24,
      cond: editing, //user.studio.multi_province && expedient,
      roles: [],
      showOnEdit: true,
      item: (
        <Form.Item key="province" label={currentLang.text.province}>
          {getFieldDecorator("province", {
            initialValue: editing
              ? expedient.expedient_province || authUser.province_id
              : authUser.studio.province_id
          })(
            <Antd4ProvinceSelect
              disabled={authUser.province_id}
              value={
                editing
                  ? expedient.expedient_province || authUser.province_id
                  : authUser.studio.province_id
              }
              country={
                editing
                  ? expedient.studio.province.country_id
                  : authUser.studio.province.country_id
              }
              onChange={p => {
                setProvince(p);
                setJurisdiction(null);
                setFuero(null);
                setCourt(null);
                setSecretary(null);
                form.setFieldsValue({
                  fuero: null,
                  court: null,
                  secretary: null,
                  jurisdiction: null
                });
              }}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "jurisdiction",
      lg: 24,
      md: 24,
      xs: 24,
      cond: editing,
      roles: [],
      showOnEdit: true,
      item: (
        <JurisdictionSelect
          province={province}
          jurisdiction={editing ? expedient.jurisdiction : null}
          getFieldDecorator={getFieldDecorator}
          onSelect={j => {
            setJurisdiction(j || null);
            setFuero(null);
            setCourt(null);
            setSecretary(null);
            form.setFieldsValue({ fuero: null, court: null, secretary: null });
          }}
        />
      )
    },
    {
      key: "fuero",
      lg: 24,
      md: 24,
      xs: 24,
      cond: editing,
      roles: [],
      showOnEdit: true,
      item: (
        <FueroSelect
          jurisdiction={jurisdiction}
          disabled={!jurisdiction}
          fuero={fuero}
          getFieldDecorator={getFieldDecorator}
          onSelect={f => {
            setFuero(f);
            setCourt(null);
            setSecretary(null);
            form.setFieldsValue({ court: null, secretary: null });
          }}
        />
      )
    },
    {
      key: "court",
      lg: 24,
      md: 24,
      xs: 24,
      cond: editing,
      roles: [],
      showOnEdit: true,
      item: (
        <CourtSelect
          fuero={fuero}
          disabled={!fuero || !jurisdiction}
          court={court}
          getFieldDecorator={getFieldDecorator}
          onSelect={court => {
            setCourt(court);
            setSecretary(null);
            form.setFieldsValue({ secretary: null });
          }}
        />
      )
    },
    {
      key: "secretary",
      lg: 24,
      md: 24,
      xs: 24,
      cond: editing,
      roles: [],
      showOnEdit: true,
      item: (
        <SecretarySelect
          court={court}
          disabled={!fuero || !jurisdiction || !court}
          secretary={editing ? expedient.secretary : null}
          getFieldDecorator={getFieldDecorator}
          onSelect={f => setSecretary(f)}
        />
      )
    }
  ];


  return (
    <>
      <Form
        onSubmit={handleSubmit}
        layout="vertical"
      >
        <Row type="flex" justify="start" gutter={20}>
          {/* Standar fields */}
          <Col span={editing && !isTabletOrMobile ? 18 : 24}>
            <Row type="flex" justify="start" gutter={20}>
              {fields
                .filter(field => (editing ? field.showOnEdit : field))
                .filter(field => field.cond)
                .map(filteredField => (
                  <Col
                    xs={filteredField.xs}
                    lg={filteredField.lg}
                    md={filteredField.md}
                  >
                    <div
                      className={
                        filteredField._tutoStep === tutoStep && showTutorial
                          ? "fixed-form-item fade-in"
                          : ""
                      }
                    >
                      {filteredField.item}
                    </div>
                  </Col>
                ))}
            </Row>
            {!isTabletOrMobile && (
              <Divider
                type="horizontal"
                orientation="left"
                style={{ borderTopColor: "#cbcaca" }}
              >
                <div
                  className="show-advanced-options"
                  onClick={() => {
                    setShowAdvancedFields(!showAdvancedFields);
                  }}
                >
                  <CaretDownOutlined
                    className={
                      showAdvancedFields ? "open-advance" : "closed-advance"
                    }
                  />{" "}
                  Opciones avanzadas
                </div>
              </Divider>
            )}
          </Col>
          {editing && (
            <Col span={isTabletOrMobile ? 24 : 6}>
              <Row type="flex" justify="start" gutter={20}>
                {judicial_fields
                  .filter(field => (editing ? field.showOnEdit : field))
                  .filter(field => field.cond)
                  .map(filteredField => (
                    <Col
                      xs={filteredField.xs}
                      lg={filteredField.lg}
                      md={filteredField.md}
                    >
                      <div
                        className={
                          filteredField._tutoStep === tutoStep && showTutorial
                            ? "fixed-form-item fade-in"
                            : ""
                        }
                      >
                        {filteredField.item}
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          )}
          {isTabletOrMobile && (
            <Divider
              type="horizontal"
              orientation="left"
              style={{ borderTopColor: "#cbcaca" }}
            >
              <div
                className="show-advanced-options"
                onClick={() => {
                  setShowAdvancedFields(!showAdvancedFields);
                }}
              >
                <CaretDownOutlined
                  className={
                    showAdvancedFields ? "open-advance" : "closed-advance"
                  }
                />{" "}
                Opciones avanzadas
              </div>
            </Divider>
          )}
          <Col span={24}>
            <CollapsablePanel
              type="block"
              vertical={true}
              open={showAdvancedFields}
            >
              <Row type="flex" justify="start" gutter={20}>
                {advanced_fields
                  .filter(field => (editing ? field.showOnEdit : field))
                  .filter(field => field.cond)
                  .map(filteredField => (
                    <Col
                      xs={filteredField.xs}
                      lg={filteredField.lg}
                      md={filteredField.md}
                    >
                      <div
                        className={
                          filteredField._tutoStep === tutoStep && showTutorial
                            ? "fixed-form-item fade-in"
                            : ""
                        }
                      >
                        {filteredField.item}
                      </div>
                    </Col>
                  ))}
              </Row>
            </CollapsablePanel>
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={20}>
          <Col lg={24} md={24} xs={24}>
            <div>
              <Form.Item key="submit">
                <Button
                  style={
                    isTabletOrMobile ? { width: "47%" } : { margin: "0px 10px" }
                  }
                  type="primary"
                  htmlType="submit"
                  className="full-input responsive-input responsive-button"
                  loading={loading}
                >
                  <CheckCircleOutlined style={{ fontSize: "16px" }} />
                  {editing ? "Editar" : "Completar"}
                </Button>
                <Button
                  style={
                    isTabletOrMobile ? { width: "47%" } : { margin: "0px 10px" }
                  }
                  type="danger"
                  onClick={() => onCancel()}
                  className="ant-btn warn responsive-input responsive-button"
                  htmlType="button"
                >
                  <CloseCircleOutlined style={{ fontSize: "16px" }} /> Cancelar
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const updateExpedient = (values, props, onError) => {
  try {
    const _expedient = {
      ...values,
      init_date: moment(values.init_date).format("YYYY-MM-DD"),
      claim_date: values.claim_date
        ? moment(values.claim_date).format("YYYY-MM-DD")
        : null,
      demand_begin_date: values.demand_begin_date
        ? moment(values.demand_begin_date).format("YYYY-MM-DD")
        : null,
      prescription_date: values.prescription_date
        ? moment(values.prescription_date).format("YYYY-MM-DD")
        : null,
      id: props.expedient.id,
      tags: explodeTags(values.tags)
    };
    ExpedientService.updateExpedient(_expedient)
      .then(response => props.onUpdated(response.data))
      .catch(err => {
        onError();
      });
  } catch (error) {
    console.log(error);
  }
};

const storeExpedient = (values, props, onError, withTutorial) => {
  ExpedientService.createExpedient({
    ...values,
    init_date: moment(values.init_date).format("YYYY-MM-DD"),
    claim_date: values.claim_date
      ? moment(values.claim_date).format("YYYY-MM-DD")
      : null,
    demand_begin_date: values.demand_begin_date
      ? moment(values.demand_begin_date).format("YYYY-MM-DD")
      : null,
    prescription_date: values.prescription_date
      ? moment(values.prescription_date).format("YYYY-MM-DD")
      : null,
    tags: explodeTags(values.tags)
  })
    .then(response => {
      const exp = response.data.created;
      props.history.push(`/expedient/details#${exp.uuid}`, { withTutorial });
      props.onStored && props.onStored();
    })
    .catch(err => {
      message.error("Ha ocurrido un error");
      onError();
    });
};

const CreateExpedientForm = Form.create({ name: "create-expedient" })(
  CreateExpedient
);

const mapStateToProps = ({ auth, lang }) => {
  const { currentLang } = lang;
  return { authUser: auth.authUser, currentLang };
};

export default connect(mapStateToProps, {})(withRouter(CreateExpedientForm));
