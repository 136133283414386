import React, { useEffect } from "react";
import { Input, Row, Col } from "antd";
import { useState } from "react";

export function ExpiresAtInput({ value, onChange }) {

  const [expiresAt, setExpiresAt] = useState(value > 0 - 1 ? value : 0);

  useEffect(() => {
    setExpiresAt(value > -1 ? value : 0);
  }, [value]);

  const handleChange = v => {
    onChange && onChange(v > -1 ? v : 0);
  };

  return (
    <Input.Group>
      <Row gutter={10}>
        <Col span={24}>
          <Input
            placeholder="No caducar"
            className="responsive-input" autoComplete="cc-csc"
            value={value > -1 ? value : 0}
            onChange={e => {
              handleChange(e.currentTarget.value);
              setExpiresAt(e.currentTarget.value);
            }}
          />
        </Col>
      </Row>
    </Input.Group>
  );
}
